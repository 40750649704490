import { Menu } from "antd";
import {
  InfoCircleOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { User } from "../Model/User";
import { StorageService } from "../Services";
import { useNavigate } from "react-router-dom";
import { useAppManager } from "../Context/App/AppManager";
import { useBoolean } from "@fluentui/react-hooks";
import { AboutPanel } from "../Home/AboutPanel/AboutPanel";

const { SubMenu } = Menu;

type CommandBarProps = {
  user?: User;
};

export function CommandBar({ user }: CommandBarProps) {
  const [isAboutPanelOpen, { setTrue: openAbout, setFalse: dismissAbout }] =
    useBoolean(false);
  const navigate = useNavigate();
  const appManager = useAppManager();

  function handleClick(key: string) {
    switch (key) {
      case "logout":
        StorageService.clear();
        appManager.setUser(undefined);
        navigate("/");
        break;
      case "management":
        appManager.setAction("openManagement");
        break;
      case "about":
        openAbout();
        break;
      default:
        break;
    }
  }

  function renderLoggedInMenu(user: User) {
    const { firstName, lastName } = user;
    return (
      <>
        <SubMenu
          key="SubMenu"
          icon={<UserOutlined />}
          title={`${firstName} ${lastName}`}
        >
          <Menu.Item key="logout" icon={<LogoutOutlined />}>
            Logout
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="management" icon={<SettingOutlined />}>
          Management
        </Menu.Item>
        <Menu.Item key="about" icon={<InfoCircleOutlined />}>
          About
        </Menu.Item>
      </>
    );
  }

  function renderLoggedOutMenu() {
    return (
      <>
        <Menu.Item key="about" icon={<InfoCircleOutlined />}>
          About
        </Menu.Item>
      </>
    );
  }

  return (
    <>
      <Menu
        style={{ paddingLeft: 24, paddingRight: 24 }}
        selectable={false}
        onClick={(e) => handleClick(e.key)}
        mode="horizontal"
      >
        {user ? renderLoggedInMenu(user) : renderLoggedOutMenu()}
      </Menu>
      <AboutPanel isPanelOpen={isAboutPanelOpen} dismissPanel={dismissAbout} />
    </>
  );
}
