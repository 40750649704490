import { Label, Stack, PrimaryButton, Text } from "@fluentui/react";
import { User } from "../../Model/User";
import { Spacer } from "../../Utils/Spacer";

type OverviewProps = {
  user?: User;
  openManagePanel: () => void;
};

export function Overview({ user, openManagePanel }: OverviewProps) {
  function renderHospitalDetail({ hospital }: User) {
    return (
      <Stack>
        <Text variant="large">{hospital?.name}</Text>
        <Text variant="medium">{hospital?.address}</Text>
        <Text variant="medium">{hospital?.phone}</Text>
        <Text variant="medium">{hospital?.email}</Text>
      </Stack>
    );
  }

  return (
    <Stack>
      <Spacer height={16} />
      <Label>Hospital</Label>
      {user ? renderHospitalDetail(user) : null}
      <Stack
        styles={{
          root: {
            width: 320,
          },
        }}
      >
        <Spacer height={16} />
        <PrimaryButton onClick={() => openManagePanel()}>
          {"Manage"}
        </PrimaryButton>
      </Stack>
    </Stack>
  );
}
