import {
  IStackStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppManager } from "../Context/App/AppManager";
import { User } from "../Model/User";
import { StorageService, post, get } from "../Services";
import { Spacer } from "../Utils/Spacer";

const stackStyle: IStackStyles = {
  root: {
    marginTop: "32px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
};

export function Landing() {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [username, setUsername] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isInitialized, setInitialized] = useState<boolean>(false);

  const appManager = useAppManager();
  const navigate = useNavigate();
  const onPasswordChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      setPassword(value);
    },
    []
  );

  const onUsernameChange = useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      setUsername(value);
    },
    []
  );

  async function onLoginClick() {
    setError(undefined);
    if (!username || !password) {
      setError("Missing username or password");
      return;
    }

    setLoading(true);

    try {
      const result: { access_token: string } = await post("auth/admin", {
        username,
        password,
      });

      StorageService.setItem("access_token", { token: result.access_token });
      const user: User = await get("users/profile", {});
      appManager.setUser(user);
      navigate("/home");
    } catch (error: any) {
      setError(error.message);
    }

    setLoading(false);
  }

  async function Initialize() {
    const token = StorageService.getToken();
    let hasValidToken = false;
    if (token) {
      try {
        const user = await get("users/profile", {});
        setUser(user);
        appManager.setUser(user);
        hasValidToken = true;
      } catch (error) {
        hasValidToken = false;
      }
    }

    if (hasValidToken) {
      setTimeout(() => {
        navigate("/home");
      }, 2000);
    } else {
      setInitialized(true);
    }
  }

  useEffect(() => {
    Initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderLoginForm() {
    return (
      <Stack styles={{ root: { width: "30vh" } }}>
        <Spacer height={32} />
        <TextField
          label="Username"
          required={true}
          placeholder="Username"
          onChange={onUsernameChange}
        />
        <TextField
          label="Password"
          required={true}
          placeholder="Password"
          type="password"
          onChange={onPasswordChange}
        />
        <Spacer height={16} />
        {error ? (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {error}
          </MessageBar>
        ) : null}
        <Spacer height={16} />
        <PrimaryButton
          text="Login"
          disabled={isLoading}
          onClick={() => onLoginClick()}
        />
        {isLoading ? <ProgressIndicator /> : null}
      </Stack>
    );
  }

  return (
    <Stack styles={stackStyle}>
      {isInitialized ? (
        renderLoginForm()
      ) : (
        <Stack styles={{ root: { width: "30vh" } }}>
          {user ? (
            <Stack>
              <Text styles={{ root: { textAlign: "center" } }} variant="medium">
                Welcome
              </Text>
              <Text styles={{ root: { textAlign: "center" } }} variant="large">
                {user?.firstName} {user?.lastName}
              </Text>
              <Spacer height={32} />
            </Stack>
          ) : (
            <Text styles={{ root: { textAlign: "center" } }} variant="tiny">
              Setting Up
            </Text>
          )}

          <ProgressIndicator />
        </Stack>
      )}
    </Stack>
  );
}
