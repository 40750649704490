import { getUnixTime } from "date-fns";

export function getTimeStamp(input?: Date) {
  const date = input ?? new Date();
  return getUnixTime(date);
}

export function getTimeFromUTC(input: number) {
  const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
  date.setUTCSeconds(input);
  return date;
}

export function getFormattedDateTime(input: Date) {
  return input.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getFormattedDate(input: Date) {
  return input.toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getFormattedTime(input: Date) {
  return input.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function getIntials(firstName?: string, lastName?: string): string {
  let result = "USER";
  if (
    firstName !== undefined &&
    lastName !== undefined &&
    firstName.length > 0 &&
    lastName.length > 0
  ) {
    result = firstName.substring(0, 1) + lastName.substring(0, 1);
  } else if (firstName !== undefined && firstName.length > 1) {
    result = firstName.substring(0, 2);
  } else if (firstName !== undefined && firstName.length > 1) {
    result = firstName.substring(0, 2);
  }

  return result;
}
