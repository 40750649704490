const store = window.sessionStorage;

type Key = "access_token";

export const StorageServices = {
  setItem<T>(key: Key, item: T) {
    store.setItem(key, JSON.stringify(item));
  },
  getItem<T>(key: Key): T | undefined {
    const item = store.getItem(key);
    if (item) {
      return JSON.parse(item) as T;
    }

    return undefined;
  },
  getToken() {
    const result = this.getItem<{ token: string }>("access_token");
    return result?.token || "";
  },
  clear() {
    store.clear();
  },
};
