import {
  Stack,
  Text,
  Panel,
  PanelType,
  Separator,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { Hospital } from "../../Model/Hospital";
import { CreateUser } from "./CreateUser";
import { UpdateHospital } from "./UpdateHospital";

export type PanelProps = {
  isPanelOpen: boolean;
  hospital?: Hospital;
  dismissPanel: () => void;
};

export function ManagementPanel({
  isPanelOpen,
  dismissPanel,
  hospital,
}: PanelProps) {
  const [isLoading, { setTrue: startLoading, setFalse: stopLoading }] =
    useBoolean(false);
  return (
    <Panel
      isOpen={isPanelOpen}
      onDismiss={dismissPanel}
      type={PanelType.medium}
    >
      <Stack>
        <Text variant="xLarge">Management</Text>
      </Stack>
      <Separator />
      <Pivot>
        <PivotItem headerText="Hospital">
          {hospital ? (
            <UpdateHospital
              isLoading={isLoading}
              startLoading={startLoading}
              stopLoading={stopLoading}
              hospital={hospital}
            />
          ) : null}
        </PivotItem>
        <PivotItem headerText="Add Patient">
          <CreateUser
            isLoading={isLoading}
            startLoading={startLoading}
            stopLoading={stopLoading}
            type={"standard"}
          />
        </PivotItem>
        <PivotItem headerText="Add Staff User">
          <CreateUser
            isLoading={isLoading}
            startLoading={startLoading}
            stopLoading={stopLoading}
            type={"staff"}
          />
        </PivotItem>
      </Pivot>
    </Panel>
  );
}
