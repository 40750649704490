import { Stack, Text, Panel, PanelType, Separator } from "@fluentui/react";
import { Space } from "antd";

export type PanelProps = {
  isPanelOpen: boolean;
  dismissPanel: () => void;
};

export function AboutPanel({ isPanelOpen, dismissPanel }: PanelProps) {
  return (
    <Panel
      isLightDismiss={true}
      isOpen={isPanelOpen}
      onDismiss={dismissPanel}
      type={PanelType.medium}
    >
      <Stack>
        <Text variant="xLarge">HomeBase</Text>
        <Text variant="medium">About</Text>
      </Stack>
      <Separator />
      <Space direction="vertical">
        <Text variant="medium">Alpha Release</Text>
        <Text variant="medium">Call: +91 8105180930</Text>
        <Text variant="medium">Mail: connect@magisklab.com</Text>
      </Space>
    </Panel>
  );
}
