/* eslint-disable no-useless-escape */
import {
  Stack,
  TextField,
  PrimaryButton,
  ProgressIndicator,
  MessageBarType,
  IDropdownOption,
  Dropdown,
  DatePicker,
  defaultDatePickerStrings,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Hospital } from "../../Model/Hospital";
import { GenderType, UserRequest } from "../../Model/User";
import { get, post } from "../../Services";
import { getTimeStamp } from "../../Utils/Helper";
import { Message, MessageView } from "../../Utils/MessageView";
import { Spacer } from "../../Utils/Spacer";

const genderOptions: IDropdownOption[] = [
  {
    key: "Male",
    text: "Male",
  },
  {
    key: "Female",
    text: "Female",
  },
  {
    key: "Other",
    text: "Other",
  },
];

type CreateUserProps = {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  type: "standard" | "staff";
};

export function CreateUser({
  isLoading,
  startLoading,
  stopLoading,
  type,
}: CreateUserProps) {
  const [dob, setDOB] = useState<Date>(new Date());
  const [gender, setGender] = useState<IDropdownOption | undefined>({
    key: "Male",
    text: "Male",
  });
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");

  const [hospital, setHospital] = useState<IDropdownOption | undefined>(
    undefined
  );
  const [hospitals, setHospitals] = useState<IDropdownOption[]>([]);

  async function fetchHospitals() {
    try {
      startLoading();
      const list: Hospital[] = await get("hospital", {});
      let options: IDropdownOption[] = [];
      list.forEach((element) => {
        options.push({ key: element.id, text: element.name });
      });

      setHospitals(options);
    } catch (error) {
      setMessage({
        text: "Failed to fetch hospital list.",
        type: MessageBarType.error,
      });
    }

    stopLoading();
  }

  function validate() {
    let result = true;
    if (!username) {
      setMessage({ text: "Username missing", type: MessageBarType.warning });
      result = false;
    } else if (!password) {
      setMessage({ text: "Password missing", type: MessageBarType.warning });
      result = false;
    } else if (!firstName) {
      setMessage({ text: "First Name missing", type: MessageBarType.warning });
      result = false;
    } else if (!lastName) {
      setMessage({ text: "Last name missing", type: MessageBarType.warning });
      result = false;
    } else if (!phone) {
      setMessage({ text: "Phone missing", type: MessageBarType.warning });
      result = false;
    } else if (!address) {
      setMessage({ text: "Address missing", type: MessageBarType.warning });
      result = false;
    }

    return result;
  }

  async function createStandardUser(request: UserRequest) {
    await post("auth/register", request);
  }

  async function createStaffUser(request: UserRequest) {
    if (hospital?.id) {
      await post("users/create_staff", {
        ...request,
        hospitalId: hospital.id,
      });
    }
  }

  function clearForm() {
    setUsername("");
    setPassword("");
    setPhone("");
    setEmail("");
    setAddress("");
    setFirstname("");
    setLastname("");
    setHospital(undefined);
  }

  async function onRegister() {
    setMessage(undefined);
    if (!validate()) {
      return;
    }

    const request: UserRequest = {
      username,
      password,
      phone,
      email,
      address,
      firstName,
      lastName,
      gender: (gender?.key || "Other") as GenderType,
      dob: getTimeStamp(dob),
    };

    startLoading();

    try {
      type === "standard"
        ? await createStandardUser(request)
        : await createStaffUser(request);
      setMessage({
        text: "User created successfully",
        type: MessageBarType.success,
      });
      clearForm();
    } catch (error) {
      setMessage({ text: "Service error", type: MessageBarType.error });
    } finally {
      stopLoading();
    }
  }

  useEffect(() => {
    if (type === "staff") {
      fetchHospitals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function onPhoneValidation(value: string) {
    const regex = new RegExp(
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    );
    return regex.test(value) ? "" : "Invalid phone number";
  }

  function onEmailValidation(value: string) {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return regexp.test(value) ? "" : "Invalid email address";
  }

  return (
    <Stack>
      <TextField
        disabled={isLoading}
        required
        value={username}
        onChange={(ev, value) => setUsername(value || "")}
        label="Username"
      />
      <TextField
        disabled={isLoading}
        required
        label="Password"
        value={password}
        onChange={(ev, value) => setPassword(value || "")}
      />
      {type === "staff" ? (
        <Dropdown
          disabled={isLoading}
          label="Hospital"
          onChange={(ev, option) => {
            setHospital(option);
          }}
          selectedKey={hospital?.key}
          options={hospitals}
        />
      ) : null}
      <DatePicker
        disabled={isLoading}
        label="Date of Birth"
        placeholder="Select Date of Birth"
        strings={defaultDatePickerStrings}
        value={dob}
        onSelectDate={(date) => setDOB(date || dob)}
      />
      <Dropdown
        disabled={isLoading}
        label="Gender"
        onChange={(ev, option) => {
          setGender(option);
        }}
        selectedKey={gender?.key}
        options={genderOptions}
      />
      <TextField
        disabled={isLoading}
        required
        label="First Name"
        value={firstName}
        onChange={(ev, value) => setFirstname(value || "")}
      />
      <TextField
        disabled={isLoading}
        required
        label="Last Name"
        value={lastName}
        onChange={(ev, value) => setLastname(value || "")}
      />
      <TextField
        disabled={isLoading}
        required
        label="Phone"
        value={phone}
        prefix="+91"
        onGetErrorMessage={onPhoneValidation}
        validateOnFocusOut={true}
        maxLength={10}
        onChange={(ev, value) => setPhone(value || "")}
      />
      <TextField
        disabled={isLoading}
        label="Email"
        value={email}
        validateOnFocusOut={true}
        onGetErrorMessage={onEmailValidation}
        onChange={(ev, value) => setEmail(value || "")}
      />
      <TextField
        disabled={isLoading}
        required
        label="Address"
        multiline
        value={address}
        onChange={(ev, value) => setAddress(value || "")}
      />
      <Spacer height={32} />
      <PrimaryButton onClick={() => onRegister()} disabled={isLoading}>
        Register
      </PrimaryButton>
      <Spacer height={8} />
      <MessageView message={message} />
      {isLoading ? <ProgressIndicator /> : null}
    </Stack>
  );
}
