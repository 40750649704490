import { MessageBarType, MessageBar } from "@fluentui/react";

export type Message = {
  text: string;
  type: MessageBarType;
};

export function MessageView({ message }: { message?: Message }) {
  return (
    <div>
      {message ? (
        <MessageBar messageBarType={message.type}>{message.text}</MessageBar>
      ) : null}
    </div>
  );
}
