import {
  Label,
  Stack,
  mergeStyleSets,
  List,
  ProgressIndicator,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback } from "react";
import { User } from "../../Model/User";
import { post } from "../../Services";
import { Spacer } from "../../Utils/Spacer";
import { getIntials } from "../../Utils/Helper";
import { Typography, Card, Input, Space } from "antd";
import Avatar from "antd/lib/avatar/avatar";

const { Text } = Typography;

type SearchPanelProps = {
  openPanel: () => void;
  setSelectedUser: (user: User) => void;
  searchResult: User[];
  setSearchResult: (users: User[]) => void;
};

const classNames = mergeStyleSets({
  container: {
    overflow: "auto",
    justifyContent: "stretch",
  },
  itemCell: [
    {
      background: "#ECF0F1",
      minHeight: 54,
      padding: 10,
      marginBottom: 8,
      boxSizing: "border-box",
      display: "flex",
      selectors: {
        "&:hover": {
          background: "white",
          boxShadow: "0px 0px 36px -9px rgba(140,121,121,1)",
          transition: "box-shadow 0.5s ease-in-out",
          cursor: "default",
        },
      },
    },
  ],
});

export function Search({
  openPanel,
  setSelectedUser,
  searchResult,
  setSearchResult,
}: SearchPanelProps) {
  const [isLoading, { setTrue: startLoading, setFalse: stopLoading }] =
    useBoolean(false);

  async function onUserSelect(user?: User) {
    if (user) {
      setSelectedUser(user);
      openPanel();
    }
  }

  const search = useCallback(
    async (searchTerm: string) => {
      if (searchTerm) {
        startLoading();
        try {
          const users = await post("users/search", {
            term: searchTerm ?? "",
          });

          setSearchResult(users);
        } catch (error) {
          console.log("search error:", error);
        } finally {
          stopLoading();
        }

        stopLoading();
      }
    },
    [setSearchResult, startLoading, stopLoading]
  );

  function onRenderCell(item?: User, index?: number) {
    const card = (
      <Card
        style={{ marginBottom: 16 }}
        hoverable
        onClick={() => onUserSelect(item)}
      >
        <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
          {getIntials(item?.firstName, item?.lastName)}
        </Avatar>
        <Spacer height={16} />
        <Card.Meta title={`${item?.firstName} ${item?.lastName}`} />
        <Space size={0} direction="vertical">
          <Space>
            <Text type="secondary">Username:</Text>
            <Text>{item?.username}</Text>
          </Space>
          <Space>
            <Text type="secondary">Phone:</Text>
            <Text>{item?.phone}</Text>
          </Space>
        </Space>
      </Card>
    );

    return card;
  }

  return (
    <Stack>
      <div
        style={{
          width: "100%",
          height: "60vh",
        }}
      >
        <Spacer height={16} />
        <Label>Enter name, phone number or address</Label>
        <Input.Search
          allowClear
          enterButton
          placeholder="Search"
          onSearch={(value) => {
            search(value);
          }}
        />
        <Spacer height={32} />
        {isLoading ? (
          <ProgressIndicator />
        ) : (
          <div
            style={{ border: "1px solid grey", height: "100%" }}
            className={classNames.container}
            data-is-scrollable
          >
            <List items={searchResult} onRenderCell={onRenderCell} />
          </div>
        )}
        <Spacer height={8} />
      </div>
    </Stack>
  );
}
