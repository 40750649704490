import { Space, Tag } from "antd";
import { Appointment, AppointmentState } from "../Model/Appointment";

export function AppointmentStats({
  appointments,
  onTagClicked,
}: {
  onTagClicked: (tag: string) => void;
  appointments: Appointment[];
}) {
  type StatsItem = {
    title: string;
    color?: string;
    key: AppointmentState;
  };

  const data: StatsItem[] = [
    { title: "OPEN", key: "open", color: "processing" },
    { title: "CLOSED", key: "closed", color: "success" },
    { title: "CANCELLED", key: "cancelled", color: "warning" },
    { title: "EXPIRED", key: "expired", color: "error" },
  ];

  function renderTag(item: StatsItem) {
    const { title, key, color } = item;
    return (
      <>
        <Tag
          style={{ cursor: "pointer" }}
          onClick={() => onTagClicked(key)}
          color={color}
        >
          {title}
        </Tag>
        <Tag
          onClick={() => onTagClicked(key)}
          style={{ cursor: "pointer" }}
          color={color}
        >
          {appointments.filter((value) => value.state === key).length}
        </Tag>
      </>
    );
  }

  return (
    <Space size={0}>
      <Tag style={{ cursor: "pointer" }} onClick={() => onTagClicked("all")}>
        All
      </Tag>
      <Tag style={{ cursor: "pointer" }}>{appointments.length}</Tag>
      {data.map((value) => renderTag(value))}
    </Space>
  );
}
