import React, { useEffect, useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { User } from "../Model/User";
import { useBoolean } from "@fluentui/react-hooks";
import { UserPanel } from "./UserPanel/UserPanel";
import { ManagementPanel } from "./ManagementPanel";
import { Overview } from "./Sections/Overview";
import { Search } from "./Sections/Search";
import { AppointmentSection } from "./Sections/AppointmentSection";
import { useAppManager } from "../Context/App/AppManager";
import { useAppContext } from "../Context/App/AppContext";
import { useNavigate } from "react-router-dom";
import "./Home.css";

export const Home: React.FunctionComponent = () => {
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [searchResult, setSearchResult] = useState<User[]>([]);
  const [isPanelOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  const [
    isManagementPanelOpen,
    { setTrue: openManagePanel, setFalse: dismissManagePanel },
  ] = useBoolean(false);

  const appManager = useAppManager();
  const navigate = useNavigate();
  const { user, action } = useAppContext();

  useEffect(() => {
    if (action) {
      switch (action) {
        case "openManagement":
          openManagePanel();
          break;
      }

      appManager.setAction(undefined);
    }
  }, [action, appManager, openManagePanel]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <div>
      <Pivot style={{ paddingLeft: 32, paddingRight: 32 }}>
        <PivotItem headerText="Overview">
          <Overview user={user} openManagePanel={openManagePanel} />
        </PivotItem>
        <PivotItem headerText="Search">
          <Search
            searchResult={searchResult}
            setSearchResult={setSearchResult}
            openPanel={openPanel}
            setSelectedUser={setSelectedUser}
          />
        </PivotItem>
        <PivotItem headerText="Appointment">
          <AppointmentSection
            openPanel={openPanel}
            setSelectedUser={setSelectedUser}
          />
        </PivotItem>
      </Pivot>

      {selectedUser ? (
        <UserPanel
          selectedUser={selectedUser}
          isPanelOpen={isPanelOpen}
          dismissPanel={dismissPanel}
        />
      ) : null}
      <ManagementPanel
        isPanelOpen={isManagementPanelOpen}
        dismissPanel={dismissManagePanel}
        hospital={user?.hospital}
      />
    </div>
  );
};
