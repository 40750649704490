import {
  Stack,
  TextField,
  PrimaryButton,
  ProgressIndicator,
  List,
  Text,
  mergeStyleSets,
  Label,
  DefaultButton,
  MessageBarType,
  Separator,
  defaultDatePickerStrings,
  IDropdownOption,
  DatePicker,
  Dropdown,
} from "@fluentui/react";
import { Card, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { Appointment } from "../../Model/Appointment";
import { Hospital } from "../../Model/Hospital";
import { User } from "../../Model/User";
import { VisitRequest } from "../../Model/Visit";
import { get, post } from "../../Services";
import {
  getFormattedDateTime,
  getTimeFromUTC,
  getTimeStamp,
} from "../../Utils/Helper";
import { Message, MessageView } from "../../Utils/MessageView";
import { Spacer } from "../../Utils/Spacer";
import { getTimeSlots } from "./Interactor";

// function getHealthStatus(): IDropdownOption[] {
//   let results: IDropdownOption[] = [];
//   results.push({ key: "ok", text: "Execellent" });
//   results.push({ key: "needs_attention", text: "Needs Attention" });
//   results.push({ key: "alarming", text: "Urgent Visit Required" });
//   return results;
// }

const classNames = mergeStyleSets({
  container: {
    maxHeight: "60vh",
    overflow: "auto",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    justifyContent: "stretch",
  },
  itemCell: [
    {
      background: "#ECF0F1",
      minHeight: 54,
      padding: 10,
      marginBottom: 8,
      boxSizing: "border-box",
      display: "flex",
      selectors: {
        "&:hover": {
          background: "white",
          boxShadow: "0px 0px 36px -9px rgba(140,121,121,1)",
          cursor: "default",
        },
      },
    },
  ],
});

export function Visit({
  isLoading,
  startLoading,
  stopLoading,
  user,
  hospital,
}: {
  user: User;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  hospital?: Hospital;
}) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [time, setTime] = useState<IDropdownOption | undefined>(undefined);
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<Appointment | undefined>(
    undefined
  );

  const config = hospital?.config;

  const [amount, setAmount] = useState("" + config?.baseFees || "");
  const [remark, setRemark] = useState("");
  const [discount, setDiscount] = useState("" + config?.discount || "");
  const [saved, setSaved] = useState("");
  const [payable, setPayable] = useState("");

  async function fetchAppointments() {
    startLoading();
    let appointments: Appointment[] = await get(
      "appointment/hospital/user/" + user.id,
      {}
    );

    appointments = appointments.filter((value) => {
      return value.state === "open";
    });

    setAppointments(appointments);
    stopLoading();
  }

  useEffect(() => {
    if (!amount || !discount) {
      setSaved("");
    }
    const _amount = parseInt(amount);
    const _discount = parseInt(discount);
    if (isNaN(_amount) || isNaN(_discount)) {
      setSaved("");
      setPayable("");
    } else {
      const _savings = _amount * (_discount / 100);
      const _payable = _amount - _savings;
      setSaved("" + _savings);
      setPayable("" + _payable);
    }
  }, [amount, discount]);

  async function onRegister() {
    const appointmentId = appointment?.id;
    if (
      !appointmentId ||
      !user.healthStatus?.id ||
      !amount ||
      !discount ||
      !saved
    ) {
      setMessage({ text: "Invalid values", type: MessageBarType.warning });
      return;
    }

    const _saved = parseInt(saved);
    const _payable = parseInt(payable);
    const _discount = parseInt(discount);

    if (isNaN(_saved) || isNaN(_payable) || isNaN(_discount)) {
      setMessage({ text: "Invalid values", type: MessageBarType.warning });
      return;
    }

    if (!time) {
      setMessage({
        text: "Please select time.",
        type: MessageBarType.error,
      });
      return;
    }

    try {
      await registerVisit(
        appointmentId,
        user.healthStatus.id,
        _saved,
        _payable,
        _discount
      );
      await bookAppointment(time);
    } catch (error) {
      return;
    }

    setMessage({
      text: "Visit registered succesfully",
      type: MessageBarType.success,
    });
    clearForm();
  }

  async function registerVisit(
    appointmentId: string,
    healthStatusId: string,
    saved: number,
    payable: number,
    discount: number
  ) {
    const userId = user.id;
    const payload: VisitRequest = {
      appointmentId,
      userId,
      remark,
      healthStatus: "ok",
      healthStatusId: healthStatusId,
      transaction: {
        discount: discount,
        amount: payable,
        saved: saved,
        mode: "cash",
      },
    };

    //Register Visit
    try {
      startLoading();
      await post("visit", payload);
    } catch (error) {
      setMessage({
        text: "Service error registering visit",
        type: MessageBarType.error,
      });
      throw new Error();
    } finally {
      stopLoading();
    }
  }

  async function bookAppointment(time: IDropdownOption) {
    const hospitalId = hospital?.id || "";
    const title = "Follow up";
    const content = remark || `Appointment at ${hospital?.name}`;
    const appointmentDate = new Date(
      selectedDate.getTime() + time.data * 60000 * 60
    );

    try {
      startLoading();
      await post("appointment", {
        title,
        content,
        date: getTimeStamp(appointmentDate),
        hospitalId,
        userId: user.id,
      });
    } catch (error) {
      setMessage({
        text: "Error creating follow up appointment, Please try from Appointment Tab",
        type: MessageBarType.error,
      });

      throw new Error();
    } finally {
      stopLoading();
    }
  }

  function clearForm() {
    setSaved("");
    setDiscount("");
    setRemark("");
    setPayable("");
    setAppointment(undefined);
    fetchAppointments();
  }

  useEffect(() => {
    if (!appointment) {
      fetchAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onRenderCell(item?: Appointment, index?: number) {
    let card = <div>Loading...</div>;
    if (item) {
      const date = getTimeFromUTC(item.date);
      card = (
        <Card
          style={{ marginBottom: 8 }}
          hoverable
          title={<Typography.Title level={4}>{item.title}</Typography.Title>}
          onClick={() => setAppointment(item)}
        >
          <Stack>
            <Typography.Title level={5}>{item.content}</Typography.Title>
            <Text>{getFormattedDateTime(date)}</Text>
          </Stack>
          <Tag color={"processing"}>{item.state.toUpperCase()}</Tag>
        </Card>
      );
    }

    return card;
  }

  function renderAppointment(appointment: Appointment) {
    const date = getTimeFromUTC(appointment.date);
    return (
      <Stack>
        <Label>Appointment:</Label>
        <Text variant="xLarge">{appointment.title}</Text>
        <Text variant="large">{appointment.content}</Text>
        <Text variant="medium">{getFormattedDateTime(date)}</Text>
        <Spacer height={8} />
        <DefaultButton onClick={() => setAppointment(undefined)}>
          Change
        </DefaultButton>
        <Spacer height={16} />
        {renderVisitForm()}
      </Stack>
    );
  }

  function renderAppointmentList() {
    if (isLoading) {
      return null;
    }
    if (appointments.length > 0) {
      return (
        <div>
          <Label>Select Appointment</Label>
          <div className={classNames.container}>
            <List items={appointments} onRenderCell={onRenderCell} />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Spacer height={8} />
          <MessageView
            message={{
              text: "Add an appointment to register visit.",
              type: MessageBarType.severeWarning,
            }}
          />
        </div>
      );
    }
  }

  function renderVisitForm() {
    return (
      <Stack>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "space-evenly",
          }}
        >
          <div>
            <Label>Saved</Label>
            <Text variant="xxLarge">₹ {saved}</Text>
          </div>
          <div>
            <Label>Payable</Label>
            <Text variant="xxLarge">₹ {payable}</Text>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              width: "100%",
              paddingRight: 8,
            }}
          >
            <TextField
              disabled={!appointment}
              required
              style={{ textAlign: "center" }}
              prefix="₹"
              label="Fees"
              onChange={(ev, value) => setAmount(value || "")}
              value={amount}
            />
          </div>
          <div
            style={{
              width: "100%",
              paddingLeft: 8,
            }}
          >
            <TextField
              prefix="%"
              disabled={!appointment}
              required
              style={{ textAlign: "center" }}
              onChange={(ev, value) => setDiscount(value || "")}
              label="Discount"
              value={discount}
            />
          </div>
        </div>

        <Separator />
        <Label>Follow up Appointment</Label>
        <DatePicker
          minDate={new Date()}
          disabled={isLoading}
          label="Date"
          placeholder="Select a date"
          strings={defaultDatePickerStrings}
          value={selectedDate}
          onSelectDate={(date) => setSelectedDate(date || selectedDate)}
        />
        <Dropdown
          onChange={(ev, option) => {
            setTime(option);
          }}
          selectedKey={time?.key}
          disabled={isLoading}
          label="Time"
          options={getTimeSlots()}
        />
        <TextField
          disabled={!appointment}
          onChange={(ev, value) => setRemark(value || "")}
          label="Remark"
          value={remark}
          placeholder="Remark"
        />
        <Spacer height={32} />
        <PrimaryButton
          disabled={isLoading || !appointment}
          onClick={() => {
            onRegister();
          }}
        >
          Submit
        </PrimaryButton>
      </Stack>
    );
  }

  return (
    <Stack>
      {appointment ? renderAppointment(appointment) : renderAppointmentList()}
      <Spacer height={8} />
      <MessageView message={message} />
      {isLoading ? <ProgressIndicator /> : null}
    </Stack>
  );
}
