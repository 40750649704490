import { IDropdownOption, PersonaPresence } from "@fluentui/react";
import { HealthStatusType } from "../Model/User";

export const searchOptions: IDropdownOption[] = [
  {
    key: "phone",
    text: "Phone Number",
  },
  {
    key: "username",
    text: "Username",
  },
  {
    key: "name",
    text: "Name",
  },
];

export function getStatus(type: HealthStatusType) {
  let result = {
    icon: PersonaPresence.none,
    colour: "white",
    text: "Unknown",
  };

  switch (type) {
    case "alarming":
      result.icon = PersonaPresence.busy;
      result.colour = "#ad1457";
      result.text = "Alarming";
      break;
    case "needs_attention":
      result.icon = PersonaPresence.away;
      result.colour = "#bc5100";
      result.text = "Needs Attention";
      break;
    case "ok":
      result.icon = PersonaPresence.online;
      result.colour = "#1b5e20";
      result.text = "Excellent";
      break;
  }

  return result;
}
