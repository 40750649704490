import { useCallback, useEffect, useState } from "react";
import {
  DatePicker,
  defaultDatePickerStrings,
  MessageBarType,
  ProgressIndicator,
  Stack,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  message as Toast,
  Button,
  Card,
  Space,
  Table,
  Typography,
  Modal,
  Tag,
} from "antd";
import { Appointment, AppointmentState } from "../../Model/Appointment";
import { post } from "../../Services";
import { getTimeFromUTC, getTimeStamp } from "../../Utils/Helper";
import { Message, MessageView } from "../../Utils/MessageView";
import { Spacer } from "../../Utils/Spacer";
import { User } from "../../Model/User";
import { DataSource, makeDataSource } from "./Interactor";
import { get } from "../../Services/Network/Network";
import { endOfDay } from "date-fns";
import { AppointmentStats } from "../../Common/AppointmentStats";

const { Text, Title } = Typography;

type AppointmentSectionProps = {
  openPanel: () => void;
  setSelectedUser: (user: User) => void;
};

const filterOptions: IDropdownOption[] = [
  { key: "all", text: "All" },
  { key: "open", text: "Open" },
  { key: "closed", text: "Closed" },
  { key: "cancelled", text: "Cancelled" },
  { key: "expired", text: "Expired" },
];

export function AppointmentSection({
  openPanel,
  setSelectedUser,
}: AppointmentSectionProps) {
  const [isLoading, { setTrue: startLoading, setFalse: stopLoading }] =
    useBoolean(false);
  const [isModalVisible, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [allAppointment, setAllAppointment] = useState<Appointment[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [appointment, setAppointment] = useState<Appointment | undefined>(
    undefined
  );

  const [selectedFilter, setSelectedFilter] = useState("all");
  const [message, setMessage] = useState<Message | undefined>(undefined);
  function onDateChanged(date: Date | null | undefined) {
    if (date) {
      setSelectedDate(date);
    }
  }
  function onCardClicked(user: User) {
    setSelectedUser(user);
    openPanel();
  }

  function getAppointmentCard(item?: Appointment) {
    let component = <div>{item?.title}</div>;
    const stateTextType = (state: AppointmentState) => {
      switch (state) {
        case "cancelled":
          return "warning";
        case "closed":
          return "success";
        case "expired":
          return "danger";
        default:
          return undefined;
      }
    };
    if (item) {
      const { title, content, date, user } = item;
      component = (
        <Card
          hoverable
          title={
            <Title level={4}>
              {user.firstName} {user.lastName}
            </Title>
          }
          onClick={() => onCardClicked(user)}
        >
          <Stack>
            <Title level={5}>{title}</Title>
            <Text type="secondary">{content}</Text>
            <Text>{getTimeFromUTC(date).toLocaleString()}</Text>
            <Text
              style={{ textTransform: "uppercase" }}
              type={stateTextType(item?.state || "open")}
            >
              {item?.state}
            </Text>
          </Stack>
        </Card>
      );
    }

    return component;
  }

  function filterAppointments(filterBy: string) {
    if (filterBy === "all") {
      setAppointments(allAppointment);
    } else {
      const filtered = allAppointment.filter(
        (value) => value.state === (filterBy as AppointmentState)
      );

      setAppointments(filtered);
    }

    setSelectedFilter(filterBy as string);
  }

  const getAppointments = useCallback(
    async (date: Date) => {
      setMessage(undefined);
      startLoading();
      const end = endOfDay(date);
      const timestamp = getTimeStamp(end);
      try {
        const result: Appointment[] = await post("appointment/hospital", {
          date: timestamp,
        });
        setAllAppointment(result);
        setAppointments(result);
        setSelectedFilter("all");
      } catch (error) {
        setMessage({
          text: "Failed to load appointments. Network Error.",
          type: MessageBarType.error,
        });
        console.log(error);
      } finally {
        stopLoading();
      }
    },
    [startLoading, stopLoading]
  );

  useEffect(() => {
    getAppointments(selectedDate);
  }, [selectedDate, getAppointments]);

  function getColumns() {
    return [
      {
        title: "Name",
        key: "name",
        render: (item: DataSource) => {
          return (
            <Stack>
              <Text>{item.name}</Text>
              <Text type="secondary">{item.phone}</Text>
            </Stack>
          );
        },
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Description",
        dataIndex: "content",
        key: "content",
      },
      {
        title: "Date",
        dataIndex: "dateText",
        key: "dateText",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        render: (state: AppointmentState) => {
          let color = "processing";
          switch (state) {
            case "cancelled":
              color = "warning";
              break;
            case "closed":
              color = "success";
              break;
            case "expired":
              color = "error";
              break;
          }

          return <Tag color={color}>{state.toUpperCase()}</Tag>;
        },
      },
      {
        title: "Actions",
        key: "action",
        render: (text: DataSource, record: any) => {
          const { appointment } = text;
          return (
            <Space>
              <Button onClick={() => onCardClicked(appointment.user)}>
                Patient Info
              </Button>
              {appointment.state === "open" ? (
                <Button
                  danger
                  onClick={() => {
                    setAppointment(appointment);
                    showModal();
                  }}
                >
                  Cancel
                </Button>
              ) : null}
            </Space>
          );
        },
      },
    ];
  }

  async function onConfirmDelete() {
    if (!appointment) {
      return;
    }

    hideModal();
    startLoading();
    try {
      await get(`appointment/cancel/${appointment.id}`);
      getAppointments(selectedDate);
      Toast.success("Appointment Cancelled.");
    } catch (error) {
      Toast.error("Failed to cancel appointment.");
    } finally {
      stopLoading();
    }
  }

  return (
    <Stack>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <DatePicker
            disabled={isLoading}
            label="Date"
            styles={{ root: { width: "160pt", marginRight: 16 } }}
            placeholder="Select a date"
            strings={defaultDatePickerStrings}
            value={selectedDate}
            showGoToToday
            onSelectDate={(date) => onDateChanged(date)}
          />
          <Dropdown
            selectedKey={selectedFilter}
            onChange={(ev, option) => {
              const state = option?.key;
              if (state) {
                filterAppointments(state as string);
              }
            }}
            disabled={isLoading}
            label="Filter By"
            styles={{ root: { width: "160pt", marginRight: 16 } }}
            options={filterOptions}
          />
        </div>

        <Space size={0} style={{ marginLeft: 16, alignItems: "end" }}>
          <AppointmentStats
            onTagClicked={(key) => filterAppointments(key)}
            appointments={allAppointment}
          />
          <PrimaryButton onClick={() => getAppointments(selectedDate)}>
            Refresh
          </PrimaryButton>
        </Space>
      </div>
      <Spacer height={8} />
      {isLoading ? (
        <ProgressIndicator />
      ) : (
        <Table
          pagination={{ pageSize: 5, position: ["bottomCenter"] }}
          scroll={{ y: "65vh" }}
          dataSource={makeDataSource(appointments)}
          columns={getColumns()}
        />
      )}
      <MessageView message={message} />
      <Modal
        title="Do you want to cancel this appointment?"
        visible={isModalVisible}
        okText="Yes"
        cancelText="No"
        okButtonProps={{ danger: true }}
        onOk={onConfirmDelete}
        onCancel={() => hideModal()}
      >
        {getAppointmentCard(appointment)}
      </Modal>
    </Stack>
  );
}
