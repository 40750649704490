import axios from "axios";
import { StorageService } from "..";

const baseUrl = "https://multiverse-api-1207.herokuapp.com/";
//const baseUrl = "http://127.0.0.1:5000/";

function makeRequestConfig(accessToken?: string) {
  const token = accessToken ?? StorageService.getToken();

  if (!token) {
    return { cancelToken: axios.CancelToken.source().token };
  }
  return {
    cancelToken: axios.CancelToken.source().token,
    headers: { Authorization: "Bearer " + token },
  };
}

export async function get(
  url: string,
  params: Record<string, string> = {},
  token?: string
) {
  const _url = baseUrl + url;
  const searchParams = new URLSearchParams(params).toString();
  let finalUrl = _url;
  if (searchParams) {
    finalUrl = finalUrl + "?" + searchParams;
  }

  const result = await axios.get(finalUrl, makeRequestConfig(token));
  return result.data;
}

export async function post(
  url: string,
  params: Record<string, string | number | object>
) {
  const _url = baseUrl + url;
  const result = await axios.post(_url, params, makeRequestConfig());
  return result.data;
}

export async function put(
  url: string,
  params: Record<string, string | number | object>
) {
  const _url = baseUrl + url;
  const result = await axios.put(_url, params, makeRequestConfig());
  return result.data;
}

export async function callDelete(
  url: string,
  params: Record<string, string> = {}
) {
  const _url = baseUrl + url;
  const searchParams = new URLSearchParams(params).toString();
  let finalUrl = _url;
  if (searchParams) {
    finalUrl = finalUrl + "?" + searchParams;
  }

  const config = await makeRequestConfig();
  const result = await axios.delete(finalUrl, config);
  return result.data;
}
