import { Label, ProgressIndicator, Stack } from "@fluentui/react";
import { Table, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { AppointmentStats } from "../../Common/AppointmentStats";
import { Appointment, AppointmentState } from "../../Model/Appointment";
import { User } from "../../Model/User";
import { get } from "../../Services";
import { getFormattedDate, getFormattedTime } from "../../Utils/Helper";
import { Spacer } from "../../Utils/Spacer";
import { DataSource, makeDataSource } from "../Sections/Interactor";

function getColumns() {
  return [
    {
      title: "Title",
      key: "title",
      render: (item: DataSource) => {
        return (
          <Stack>
            <Typography.Text>{item.title}</Typography.Text>
            <Typography.Text type="secondary">{item.content}</Typography.Text>
          </Stack>
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (item: DataSource) => {
        return (
          <Stack>
            <Typography.Text>{getFormattedDate(item.date)}</Typography.Text>
            <Typography.Text type="secondary">
              {getFormattedTime(item.date)}
            </Typography.Text>
          </Stack>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state: AppointmentState) => {
        let color = "processing";
        switch (state) {
          case "cancelled":
            color = "warning";
            break;
          case "closed":
            color = "success";
            break;
          case "expired":
            color = "error";
            break;
        }

        return <Tag color={color}>{state.toUpperCase()}</Tag>;
      },
    },
  ];
}

export function History({
  user,
  isLoading,
  startLoading,
  stopLoading,
}: {
  user: User;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}) {
  const [allAppointment, setAllAppointment] = useState<Appointment[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const getAppointments = useCallback(
    async (user: User) => {
      startLoading();
      try {
        const result: Appointment[] = await get(
          `appointment/hospital/user/${user.id}`
        );
        setAppointments(result);
        setAllAppointment(result);
      } catch (error) {
        console.log(error);
      } finally {
        stopLoading();
      }
    },
    [startLoading, stopLoading]
  );

  function filterAppointments(filterBy: string) {
    if (filterBy === "all") {
      setAppointments(allAppointment);
    } else {
      const filtered = allAppointment.filter(
        (value) => value.state === (filterBy as AppointmentState)
      );

      setAppointments(filtered);
    }

    //setSelectedFilter(filterBy as string);
  }

  useEffect(() => {
    getAppointments(user);
  }, [user, getAppointments]);
  return (
    <Stack>
      <Label>Appointment History</Label>
      {isLoading ? (
        <ProgressIndicator />
      ) : (
        <Stack>
          <Spacer height={16} />
          <AppointmentStats
            onTagClicked={(key) => filterAppointments(key)}
            appointments={allAppointment}
          />
          <Spacer height={16} />
          <Table
            pagination={{ pageSize: 5, position: ["bottomCenter"] }}
            dataSource={makeDataSource(appointments)}
            columns={getColumns()}
          />{" "}
        </Stack>
      )}
    </Stack>
  );
}
