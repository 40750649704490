import {
  Stack,
  Label,
  TextField,
  IDropdownOption,
  DropdownMenuItemType,
  Dropdown,
  PrimaryButton,
  ProgressIndicator,
  MessageBarType,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { PushNotificationRequest, User } from "../../Model/User";
import { post } from "../../Services";
import { Message, MessageView } from "../../Utils/MessageView";
import { Spacer } from "../../Utils/Spacer";

const options: IDropdownOption[] = [
  {
    key: "template1",
    text: "Missed Appointment",
    data: {
      title: "You have missed your recent appointment.",
      body: "Tap to open Freedle and book a new one.",
    },
  },
  {
    key: "template2",
    text: "Upcoming Appointment",
    data: {
      title: "You have an upcoming appointment.",
      body: "Tap to view details.",
    },
  },
  { key: "divider_1", text: "-", itemType: DropdownMenuItemType.Divider },
  {
    key: "custom",
    text: "Custom",
    data: {
      title: "",
      body: "",
    },
  },
];

export function NotificationPanel({
  user,
  isLoading,
  startLoading,
  stopLoading,
}: {
  user: User;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}) {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [template, setTemplate] = useState<IDropdownOption | undefined>(
    options[0]
  );
  const [message, setMessage] = useState<Message | undefined>(undefined);

  useEffect(() => {
    if (template?.data) {
      setTitle(template.data.title);
      setBody(template.data.body);
    }
  }, [template]);

  async function sendNotification() {
    setMessage(undefined);
    if (!title || !body) {
      setMessage({
        text: "Please enter all values.",
        type: MessageBarType.warning,
      });
      return;
    }

    const request: PushNotificationRequest = {
      userId: user.id,
      title,
      body,
    };

    startLoading();
    try {
      await post("users/notify", request);
      setMessage({
        text: "Push notification sent successfully.",
        type: MessageBarType.success,
      });
    } catch (error) {
      setMessage({
        text: "Failed to send notification. Network Error.",
        type: MessageBarType.error,
      });
    } finally {
      stopLoading();
    }
  }

  return (
    <Stack>
      <Label>Send Notification</Label>
      <Dropdown
        onChange={(ev, option) => {
          setTemplate(option);
        }}
        disabled={isLoading}
        label="Templates"
        options={options}
        selectedKey={template?.key}
      />
      <TextField
        disabled={isLoading}
        required
        value={title}
        label="Title"
        onChange={(ev, value) => {
          setTitle(value ?? "");
        }}
      />
      <TextField
        disabled={isLoading}
        required
        value={body}
        label="Description"
        onChange={(ev, value) => {
          setBody(value ?? "");
        }}
      />
      <Spacer height={32} />
      <PrimaryButton disabled={isLoading} onClick={() => sendNotification()}>
        Send Push Notification
      </PrimaryButton>
      <Spacer height={8} />
      <MessageView message={message} />
      {isLoading ? <ProgressIndicator /> : null}
    </Stack>
  );
}
