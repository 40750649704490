import {
  IStackStyles,
  Label,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  ProgressIndicator,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../Context/App/AppContext";
import { User } from "../../Model/User";
import { get } from "../../Services";
import { getStatus } from "../Interactor";
import { AppointmentPanel } from "./AppointmentPanel";
import { History } from "./History";
import { NotificationPanel } from "./NotificationPanel";
import { Visit } from "./Visit";

export type UserPanelProps = {
  isPanelOpen: boolean;
  dismissPanel: () => void;
  selectedUser: User;
};

export function UserPanel({
  isPanelOpen,
  dismissPanel,
  selectedUser,
}: UserPanelProps) {
  const [
    isUserLoading,
    { setTrue: startUserLoading, setFalse: stopUserLoading },
  ] = useBoolean(false);
  const [isLoading, { setTrue: startLoading, setFalse: stopLoading }] =
    useBoolean(false);
  const [user, setUser] = useState(selectedUser);

  const appContext = useAppContext();
  const hospital = appContext.user?.hospital;

  const status = getStatus(user.healthStatus?.status || "unknown");
  const stackStyle: IStackStyles = {
    root: {
      color: "white",
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 8,
      textAlign: "right",
      marginTop: "8px",
    },
  };

  const fetchUserDetails = useCallback(
    async ({ id }: User) => {
      startUserLoading();
      try {
        const user: User = await get("users/" + id, {});
        setUser(user);
      } catch (error) {
      } finally {
        stopUserLoading();
      }
    },
    [startUserLoading, stopUserLoading]
  );

  useEffect(() => {
    fetchUserDetails(selectedUser);
  }, [selectedUser, fetchUserDetails]);

  function renderPanel() {
    const defaultProps = {
      user,
      isLoading,
      startLoading,
      stopLoading,
    };
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: status.colour,
            marginLeft: "-24px",
            marginRight: "-24px",
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <Stack>
            <Text variant="xLarge" styles={{ root: { color: "white" } }}>
              {user.firstName} {user.lastName}
            </Text>
            <Text variant="medium" styles={{ root: { color: "white" } }}>
              {user.phone}
            </Text>
          </Stack>
          {user.healthStatus !== undefined ? (
            <Stack styles={stackStyle}>
              <Text variant="xLarge" styles={{ root: { color: "white" } }}>
                {status.text}
              </Text>
              <Text variant="medium" styles={{ root: { color: "white" } }}>
                Follow Up Status
              </Text>
            </Stack>
          ) : null}
        </div>
        <Pivot>
          <PivotItem headerText="Details">
            <Stack>
              <TextField label="First Name" value={user.firstName} />
              <TextField label="Last Name" value={user.lastName} />
              <TextField label="Username" value={user.username} />
              <TextField label="Email" value={user.email} />
              <TextField label="Phone" value={user.phone} />
            </Stack>
          </PivotItem>
          <PivotItem headerText="Add Appointment">
            <Stack>
              {user ? <AppointmentPanel {...defaultProps} /> : null}
            </Stack>
          </PivotItem>
          <PivotItem headerText="Close Visit">
            <Stack>
              <Visit {...defaultProps} hospital={hospital} />
            </Stack>
          </PivotItem>
          <PivotItem headerText="History">
            <History {...defaultProps} />
          </PivotItem>
          <PivotItem headerText="Notification">
            <Stack>
              <NotificationPanel {...defaultProps} />
            </Stack>
          </PivotItem>
        </Pivot>
      </>
    );
  }

  function renderLoader() {
    return (
      <Stack>
        <Text variant="xLarge">
          {selectedUser.firstName} {selectedUser.lastName}
        </Text>
        <Label>Loading</Label>
        <ProgressIndicator />
      </Stack>
    );
  }

  return (
    <Panel
      isOpen={isPanelOpen}
      onDismiss={dismissPanel}
      type={PanelType.medium}
    >
      {isUserLoading ? renderLoader() : renderPanel()}
    </Panel>
  );
}
