import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AppConfig, useAppManager } from "./AppManager";

type AppContextProps = PropsWithChildren<{}>;

export const AppContext = createContext<AppConfig>({});

export function AppContextProvider({
  children,
}: AppContextProps): ReactElement {
  const manager = useAppManager();
  const [config, setConfig] = useState<AppConfig>(manager.getConfig());

  const onConfigChange = useCallback(() => {
    if (manager) {
      setConfig({ ...manager.getConfig() });
    }
  }, [manager]);

  useEffect(() => {
    setConfig(manager.getConfig());
    manager.addChangeCallback(onConfigChange);
    return () => {
      manager.removeChangeCallback(onConfigChange);
    };
  }, [manager, onConfigChange]);

  return <AppContext.Provider value={config}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppConfig {
  return useContext(AppContext);
}
