import React, { Suspense } from "react";
import { initializeIcons } from "@fluentui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./Home/Home";
import { Landing } from "./Landing/Landing";
import { BrandBar } from "./Common/BrandBar";
import { Layout } from "antd";
import "./App.less";
import "antd/dist/antd.min.css";
import { AppContextProvider, useAppContext } from "./Context/App/AppContext";
import { CommandBar } from "./Common/CommandBar";

const { Header, Content } = Layout;

function MainApp() {
  const { user } = useAppContext();
  return (
    <Layout
      style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <Layout>
        <Header>
          <BrandBar />
        </Header>
        <Content>
          <BrowserRouter>
            <CommandBar user={user} />
            <Suspense fallback={<div>Loading</div>}>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/home" element={<Home />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </Content>
      </Layout>
    </Layout>
  );
}

export const App: React.FunctionComponent = () => {
  return (
    <AppContextProvider>
      <MainApp />
    </AppContextProvider>
  );
};

initializeIcons();
