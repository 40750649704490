import { Appointment, AppointmentState } from "../../Model/Appointment";
import {
  getFormattedDateTime,
  getTimeFromUTC,
  getTimeStamp,
} from "../../Utils/Helper";

export type DataSource = {
  key: string;
  name: string;
  title: string;
  phone: string;
  content: string;
  dateText: string;
  date: Date;
  state: AppointmentState;
  appointment: Appointment;
};

export function makeDataSource(appointments: Appointment[]) {
  const dataSource: DataSource[] = [];
  appointments.forEach((element) => {
    const { user, title, content, date, id, state } = element;
    const item: DataSource = {
      key: id,
      title,
      content,
      dateText: getFormattedDateTime(getTimeFromUTC(date)),
      date: getTimeFromUTC(date),
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      state,
      appointment: element,
    };

    dataSource.push(item);
  });

  dataSource.sort((a, b) => {
    return getTimeStamp(a.date) - getTimeStamp(b.date);
  });

  return dataSource;
}
