import {
  DatePicker,
  Stack,
  TextField,
  defaultDatePickerStrings,
  PrimaryButton,
  Dropdown,
  IDropdownOption,
  ProgressIndicator,
  MessageBarType,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Hospital } from "../../Model/Hospital";
import { User } from "../../Model/User";
import { get, post } from "../../Services";
import { getTimeStamp } from "../../Utils/Helper";
import { Message, MessageView } from "../../Utils/MessageView";
import { Spacer } from "../../Utils/Spacer";
import { getTimeSlots } from "./Interactor";

export type HospitalOptions = {
  hospitals: Hospital[];
  options: IDropdownOption[];
};

export function AppointmentPanel({
  user,
  isLoading,
  startLoading,
  stopLoading,
}: {
  user: User;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [time, setTime] = useState<IDropdownOption | undefined>(undefined);
  const [hospital, setHospital] = useState<IDropdownOption | undefined>(
    undefined
  );
  const [hospitals, setHospitals] = useState<HospitalOptions | undefined>(
    undefined
  );
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState<Message | undefined>(undefined);

  async function fetchHospitals() {
    const list: Hospital[] = await get("hospital", {});
    let options: IDropdownOption[] = [];
    list.forEach((element) => {
      options.push({ key: element.id, text: element.name });
    });

    setHospitals({ hospitals: list, options });
  }

  async function onAddAppointment() {
    setMessage(undefined);
    if (!hospital?.key || !time || !title || !content) {
      setMessage({
        text: "Please enter valid values",
        type: MessageBarType.error,
      });
      return;
    }

    const appointmentDate = new Date(
      selectedDate.getTime() + time.data * 60000 * 60
    );

    const hospitalId = hospital.key;
    try {
      startLoading();
      await post("appointment", {
        title,
        content,
        date: getTimeStamp(appointmentDate),
        hospitalId,
        userId: user.id,
      });
      stopLoading();
    } catch (error) {
      setMessage({
        text: "Failed to create appointment.",
        type: MessageBarType.error,
      });
      stopLoading();
      return;
    }

    setMessage({
      text: "Appointment added successfully.",
      type: MessageBarType.success,
    });

    setTitle("");
    setContent("");
  }

  useEffect(() => {
    fetchHospitals();
  }, []);
  return (
    <Stack>
      <TextField
        disabled={isLoading}
        required
        value={title}
        label="Title"
        onChange={(ev, value) => {
          setTitle(value ?? "");
        }}
      />
      <TextField
        disabled={isLoading}
        required
        value={content}
        label="Description"
        onChange={(ev, value) => {
          setContent(value ?? "");
        }}
      />
      <Dropdown
        disabled={isLoading}
        label="Hospital"
        onChange={(ev, option) => {
          setHospital(option);
        }}
        selectedKey={hospital?.key}
        options={hospitals?.options ?? []}
      />
      <DatePicker
        minDate={new Date()}
        disabled={isLoading}
        label="Date"
        placeholder="Select a date"
        strings={defaultDatePickerStrings}
        value={selectedDate}
        onSelectDate={(date) => setSelectedDate(date || selectedDate)}
      />
      <Dropdown
        onChange={(ev, option) => {
          setTime(option);
        }}
        selectedKey={time?.key}
        disabled={isLoading}
        label="Time"
        options={getTimeSlots()}
      />
      <Spacer height={32} />
      <PrimaryButton
        disabled={isLoading}
        title="Add Appointment"
        onClick={() => onAddAppointment()}
      >
        Add Appointment
      </PrimaryButton>
      <Spacer height={8} />
      <MessageView message={message} />
      {isLoading ? <ProgressIndicator /> : null}
    </Stack>
  );
}
