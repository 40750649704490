import { IDropdownOption } from "@fluentui/react";

export function getTimeSlots(): IDropdownOption[] {
  let results: IDropdownOption[] = [];
  results.push({ key: "1", text: "10:00", data: 10 });
  results.push({ key: "2", text: "11:00", data: 11 });
  results.push({ key: "3", text: "12:00", data: 12 });
  results.push({ key: "4", text: "13:00", data: 13 });
  results.push({ key: "5", text: "14:00", data: 14 });
  results.push({ key: "6", text: "15:00", data: 15 });
  results.push({ key: "7", text: "16:00", data: 16 });
  results.push({ key: "8", text: "17:00", data: 17 });
  results.push({ key: "9", text: "18:00", data: 18 });
  results.push({ key: "10", text: "19:00", data: 19 });
  results.push({ key: "11", text: "20:00", data: 20 });
  results.push({ key: "12", text: "21:00", data: 21 });
  results.push({ key: "13", text: "22:00", data: 22 });
  return results;
}
