import { createContext, useContext } from "react";
import { User } from "../../Model/User";

type Actions = "openManagement" | "openAbout";

export type AppConfig = {
  user?: User;
  action?: Actions;
};

export type ChangeCallback = () => void;

export type AppManager = {
  addChangeCallback: (callback: ChangeCallback) => void;
  removeChangeCallback: (callback: ChangeCallback) => void;
  setUser(user: User | undefined): void;
  setAction(action?: Actions): void;
  getConfig(): AppConfig;
  getUser(): User | undefined;
};

export const makeAppManager = (): AppManager => {
  let user: User | undefined;
  let action: Actions | undefined;
  const callbacks: ChangeCallback[] = [];
  const notifyCallback = (): void => {
    callbacks.forEach((callback) => callback());
  };

  return {
    addChangeCallback: (callback: ChangeCallback) => {
      if (callbacks.indexOf(callback) === -1) {
        callbacks.push(callback);
      }
    },

    removeChangeCallback: (callback: ChangeCallback) => {
      const i = callbacks.indexOf(callback);
      if (i !== -1) {
        delete callbacks[i];
      }
    },

    setAction(newAction?: Actions) {
      action = newAction;
      notifyCallback();
    },

    setUser(newUser: User) {
      user = newUser;
      notifyCallback();
    },

    getConfig() {
      return { user, action };
    },

    getUser() {
      return user;
    },
  };
};

export const AppManagerContext = createContext<AppManager>(makeAppManager());

export function useAppManager() {
  return useContext(AppManagerContext);
}
