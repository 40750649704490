import {
  Stack,
  TextField,
  PrimaryButton,
  ProgressIndicator,
  MessageBarType,
} from "@fluentui/react";
import { useState } from "react";
import { Hospital, HospitalRequest } from "../../Model/Hospital";
import { put } from "../../Services";
import { Message, MessageView } from "../../Utils/MessageView";
import { Spacer } from "../../Utils/Spacer";

type UpdateHospitalProps = {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
  hospital: Hospital;
};

export function UpdateHospital({
  isLoading,
  startLoading,
  stopLoading,
  hospital,
}: UpdateHospitalProps) {
  const [message, setMessage] = useState<Message | undefined>(undefined);
  const [name, setName] = useState(hospital?.name);
  const [lat, setLat] = useState("" + hospital?.lat);
  const [lng, setLng] = useState("" + hospital?.lng);
  const [phone, setPhone] = useState(hospital?.phone);
  const [email, setEmail] = useState(hospital?.email);
  const [address, setAddress] = useState(hospital?.address);

  function validate() {
    let result = true;
    if (!name) {
      setMessage({ text: "Name missing", type: MessageBarType.warning });
      result = false;
    } else if (!lat) {
      setMessage({ text: "Lat missing", type: MessageBarType.warning });
      result = false;
    } else if (!lng) {
      setMessage({ text: "Lng missing", type: MessageBarType.warning });
      result = false;
    } else if (!phone) {
      setMessage({ text: "Phone missing", type: MessageBarType.warning });
      result = false;
    } else if (!address) {
      setMessage({ text: "Address missing", type: MessageBarType.warning });
      result = false;
    } else if (!email) {
      setMessage({ text: "Email missing", type: MessageBarType.warning });
      result = false;
    }

    return result;
  }

  async function onUpdate() {
    setMessage(undefined);
    if (!validate()) {
      return;
    }

    const request: HospitalRequest = {
      name,
      phone,
      email,
      address,
      lat,
      lng,
    };

    startLoading();

    try {
      await put("hospital/" + hospital?.id, request);
      setMessage({
        text: "Hospital detail updated.",
        type: MessageBarType.success,
      });
    } catch (error) {
      setMessage({ text: "Service error", type: MessageBarType.error });
    } finally {
      stopLoading();
    }
  }

  return (
    <Stack>
      <TextField
        label="Hospital Name"
        onChange={(ev, value) => setName(value || "")}
        value={name}
      />
      <TextField
        label="Address"
        onChange={(ev, value) => setAddress(value || "")}
        multiline
        resizable={false}
        value={address}
      />
      <TextField
        label="Latitude"
        onChange={(ev, value) => setLat(value || "")}
        value={lat}
      />
      <TextField
        label="Longitude"
        onChange={(ev, value) => setLng(value || "")}
        value={lng}
      />
      <TextField
        label="Email"
        onChange={(ev, value) => setEmail(value || "")}
        value={email}
      />
      <TextField
        label="Phone"
        onChange={(ev, value) => setPhone(value || "")}
        value={phone}
      />
      <Spacer height={32} />
      <PrimaryButton onClick={() => onUpdate()}>{"Update"}</PrimaryButton>
      <MessageView message={message} />
      {isLoading ? <ProgressIndicator /> : null}
    </Stack>
  );
}
