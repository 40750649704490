import { FontIcon, mergeStyles, Text } from "@fluentui/react";
import { Space } from "antd";

export function BrandBar() {
  const iconClass = mergeStyles({
    fontSize: 24,
    height: 24,
    width: 24,
    marginRight: 4,
    marginTop: 4,
    color: "white",
  });

  return (
    <Space direction="horizontal" align="center">
      <FontIcon
        aria-label="Homebase Logo"
        iconName="DelveAnalyticsLogo"
        className={iconClass}
      />
      <Text style={{ color: "white" }} variant="xLarge">
        HomeBase
      </Text>
    </Space>
  );
}
